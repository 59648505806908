import {
  AudioVisualArticle,
  ImageFieldsFragment,
  OnGallery,
  OnPicture,
  OnStoryElementWithRelation,
  TextualArticle,
  isAudioVisualArticleContext,
  isElementGallery,
  isElementPicture,
  isElementWithRelation,
  isTextualArticleContext,
} from '@hubcms/domain-cook';
import { getElement } from '@hubcms/utils-story-elements';

export function getImage(context: AudioVisualArticle['context'] | TextualArticle['context']): string[] | undefined {
  if (isTextualArticleContext(context)) {
    const heroMediaElement = getElement(context.elements, 'hero_media');
    return !heroMediaElement || !isElementWithRelation(heroMediaElement) ? undefined : getHeroMediaImage(heroMediaElement);
  }
  if (isAudioVisualArticleContext(context)) {
    if (!context.teaserImage || context.teaserImage.length < 1) {
      return undefined;
    }
    const { caption, credit, title, ...imageRatios } = context.teaserImage[0].content.fields;
    const imageUrls = Object.values(imageRatios).map(value => value.href_full);
    return imageUrls.length > 0 ? imageUrls : undefined;
  }
  return undefined;
}

function getHeroMediaImage(heroMediaElement: OnStoryElementWithRelation) {
  if (isElementPicture(heroMediaElement.relation)) {
    return getPicture(heroMediaElement.relation);
  }
  if (isElementGallery(heroMediaElement.relation)) {
    return getFirstGalleryPicture(heroMediaElement.relation);
  }
  return undefined;
}

function getPicture(picture: OnPicture) {
  const imageFields = picture.fields;
  const imageUrls = Object.values(imageFields)
    .filter(isImageFieldsFragment)
    .map(value => value.href_full);
  return imageUrls.length > 0 ? imageUrls : undefined;
}

function getFirstGalleryPicture(gallery: OnGallery) {
  const firstImage = gallery.relatedImages.at(0);
  if (!firstImage) {
    return undefined;
  }
  const imageFields = firstImage.content.fields;
  const imageUrls = Object.values(imageFields)
    .filter(isImageFieldsFragment)
    .map(value => value.href_full);
  return imageUrls.length > 0 ? imageUrls : undefined;
}

function isImageFieldsFragment(object: unknown): object is ImageFieldsFragment {
  return !!object && typeof object === 'object' && '__typename' in object && object.__typename === 'Representation';
}
