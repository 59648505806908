import { createContext } from 'react';

import { CookData } from '@hubcms/domain-cook';

// TODO: Should be Context using CookData | null type (HC-1949)
export const CookContext = createContext<CookData<unknown>>({
  sectionParams: {},
  declaredParams: {},
  todaysDate: '',
});
