import { customConsent } from '@hubcms/brand';

import { CustomConsentImplProps } from '@hubcms/domain-consent';

import { ZweiwegenModel } from '../../../implementations/zweiwegen-model';

export type CustomConsentProps = CustomConsentImplProps;

export function CustomConsent({ ...implProps }: CustomConsentProps) {
  switch (customConsent) {
    case 'zweiwegenmodel':
      return <ZweiwegenModel {...implProps} />;
    default:
      return null;
  }
}
