import Script from 'next/script';
import { ReactElement } from 'react';

import { useCook } from '@hubcms/data-access-cook';
import { CookData, isArticleContext } from '@hubcms/domain-cook';

import { mapStructuredDataNewsArticle } from '../../../utils/mapStructuredDataNewsArticle';

const CreativeWorkSchema = (): ReactElement | null => {
  const cookData: CookData = useCook();

  if (!isArticleContext(cookData.context)) {
    return null;
  }

  const specs = mapStructuredDataNewsArticle(cookData.context);

  return (
    <Script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(specs, null, 2),
      }}
    />
  );
};

export default CreativeWorkSchema;
