import { ReactElement, ReactNode } from 'react';

import { CookData } from '@hubcms/domain-cook';

import { CookContext } from './CookContext';

type CookProviderProps = {
  children?: ReactNode;
  value: CookData;
};

export function CookProvider({ children, value }: CookProviderProps): ReactElement {
  return <CookContext.Provider value={value}>{children}</CookContext.Provider>;
}
