import { AudioVisualArticle, TextualArticle } from '@hubcms/domain-cook';
import { NewsArticle, WithContext } from 'schema-dts';

import { STATIC_PREMIUM_CONTENT_CSS_CLASS } from '@hubcms/domain-paywall';

import { getHeadline } from './getHeadline';
import { getImage } from './getImage';
import { getIntro } from './getIntro';
import { mapStructuredDataAuthor } from './mapStructuredDataAuthor';

export const mapStructuredDataNewsArticle = (
  context: AudioVisualArticle['context'] | TextualArticle['context'],
): WithContext<NewsArticle> => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  isAccessibleForFree: context.fields.isPremium ? 'False' : 'True',
  datePublished: context.published || undefined,
  dateModified: context.updated,
  headline: getHeadline(context),
  description: getIntro(context),
  image: getImage(context),
  author: context.authors.length > 0 ? context.authors.map(author => mapStructuredDataAuthor(author)) : undefined,
  hasPart: context.fields.isPremium
    ? {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: `.${STATIC_PREMIUM_CONTENT_CSS_CLASS}`,
      }
    : undefined,
});
