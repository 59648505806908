import { ReactElement, useEffect } from 'react';
import Script from 'next/script';
import getConfig from 'next/config';
import { logoVersion, logoSlug } from '@hubcms/brand';

import { useCook } from '@hubcms/data-access-cook';

import { UaSDK } from './domain/UaSDK';
import { AlertPromptOptions, HtmlPromptPlugin } from './domain/HtmlPromptPlugin';

declare global {
  interface Window {
    UA: Promise<UaSDK>;
  }
}

export function AirshipHead(): ReactElement | null {
  const data = useCook();
  const { publicRuntimeConfig } = getConfig();

  const key = data.sectionParams['webpush.appkey'];
  const token = data.sectionParams['webpush.token'];
  const vapidpublickey = data.sectionParams['webpush.vapidpublickey'];
  const sdkLocale = publicRuntimeConfig.NEXT_PUBLIC_AIRSHIP_SDK;
  const stylesheetURL = `https://${publicRuntimeConfig.PILOT_HOSTNAME}/airship-prompt.css`;
  const { MH_THEME: theme, MH_FOCUS_DOMAIN: domain } = publicRuntimeConfig;
  const logoURL = `https://focus.${
    domain || 'mediahuisgroup.com'
  }/raw/prod-mh-design-system/${logoVersion}/${theme}/logos/${logoSlug}.png`;

  const { sectionParams } = useCook();

  // required for the serviceworker
  useEffect(() => {
    const airshipConfigObj = {
      title: sectionParams['webpush.consent.title'] ?? '',
      message: sectionParams['webpush.consent.message'],
      accept: sectionParams['webpush.consent.accept'],
      deny: sectionParams['webpush.consent.decline'],
    };

    if (airshipConfigObj.message && airshipConfigObj.accept && airshipConfigObj.deny) {
      const options: AlertPromptOptions = {
        stylesheet: stylesheetURL,
        appearDelay: 1,
        position: 'top',
        logo: logoURL,
        i18n: {
          en: airshipConfigObj,
          de: airshipConfigObj,
          nl: airshipConfigObj,
          fr: airshipConfigObj,
          pt: airshipConfigObj,
        },
      };
      window.UA?.then(sdk =>
        sdk.plugins.load<HtmlPromptPlugin>(
          'html-prompt',
          `https://aswpsdk${sdkLocale}.com/notify/v1/ua-html-prompt.min.js`,
          options,
        ),
      ).then(plugin => plugin.prompt());
    }
  });

  if (!key || !token || !vapidpublickey) return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="service-worker-loader"
        dangerouslySetInnerHTML={{
          __html: `window.addEventListener('load', () => {
            // Is service worker available?
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.register('/push-worker.js').then(() => {
                console.log('Service worker registered!');
              }).catch((error) => {
                console.warn('Error registering service worker:');
                console.warn(error);
              });
            }
          });
            `,
        }}
      />
      <Script
        strategy="afterInteractive"
        id="airship-head"
        dangerouslySetInnerHTML={{
          __html: `!function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
          return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
          ;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
          a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdk${sdkLocale}.com/notify/v1/ua-sdk.min.js',
            'UA', {
              vapidPublicKey: '${vapidpublickey}',
              websitePushId: 'web.mediahuis.airship',        
              appKey: '${key}',
              token: '${token}'
            });
            `,
        }}
      />
    </>
  );
}
