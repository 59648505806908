import { ElementsFragment, isElementBooleanField, isElementNumberField, isElementTextField } from '@hubcms/domain-cook';

export function getElementField<T>(fields: ElementsFragment['fields'] | undefined, name: string): T | null {
  if (!fields) {
    return null;
  }
  const field = fields.find(f => f.name === name);
  if (isElementNumberField(field)) {
    return field.numberValue as T;
  }
  if (isElementBooleanField(field)) {
    return field.booleanValue as T;
  }
  if (isElementTextField(field)) {
    return field.value as T;
  }
  return null;
}
