import { ReactElement } from 'react';
import Head from 'next/head';

import { CookData } from '@hubcms/domain-cook';
import { getPageHref } from '@hubcms/feature-cook';

type CanonicalLinkProps = {
  cookData: CookData;
};

export default function CanonicalLink({ cookData }: CanonicalLinkProps): ReactElement {
  const href = getPageHref({ data: cookData, isCanonical: true });

  return <Head>{href && <link rel="canonical" href={href} />}</Head>;
}
