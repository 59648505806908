import { ReactElement } from 'react';
import Head from 'next/head';

import {
  isAudioVisualArticleContext,
  isArticleContext,
  isContextWithElements,
  isElementTextField,
  CookData,
  isTextualArticleContext,
  isExtraPageContext,
  isTagPage,
} from '@hubcms/domain-cook';

type MetaTagsProps = {
  cookData: CookData;
};

export default function MetaTags({ cookData }: MetaTagsProps): ReactElement {
  const elements = isContextWithElements(cookData.context) ? cookData?.context?.elements : [];

  let robotsValue = ['index', 'follow'];

  const intro = elements.find(el => el.type === 'intro');
  let descriptionValue = '';
  if (isTextualArticleContext(cookData.context)) {
    const paragraph = cookData.context.elements.find(el => el.type === 'paragraph');
    descriptionValue = (isElementTextField(paragraph?.fields?.[0]) && paragraph.fields[0].value) || '';
  }
  if (isElementTextField(intro?.fields?.[0])) {
    descriptionValue = intro?.fields?.[0]?.value ?? '';
  }

  if (
    cookData?.resolution?.context === 'sec' &&
    (cookData.declaredParams?.['section.seo.description'] || cookData.sectionParams?.['section.seo.description'])
  ) {
    descriptionValue =
      cookData.declaredParams?.['section.seo.description'] ?? cookData.sectionParams?.['section.seo.description'];
  }

  if (isTagPage(cookData)) {
    // @ts-expect-error TS2339
    robotsValue = cookData.intro[0]?.fields?.indexable === true ? ['index', 'max-image-preview:large'] : ['noindex'];
    // @ts-expect-error TS2339
    robotsValue.push(cookData.intro[0]?.fields?.followlinks === true ? 'follow' : 'nofollow');
    // @ts-expect-error TS2339
    if (cookData.intro[0]?.isPremium) {
      robotsValue.push('noarchive');
    }
    // @ts-expect-error TS2339
    if (cookData.intro[0]?.fields?.metaDescription) {
      // @ts-expect-error TS2339
      descriptionValue = cookData.intro[0]?.fields?.metaDescription;
    }
  }

  if (isArticleContext(cookData.context) || isExtraPageContext(cookData.context)) {
    const contextFields = cookData.context.fields;
    robotsValue =
      'indexable' in contextFields && contextFields.indexable === true ? ['index', 'max-image-preview:large'] : ['noindex'];
    robotsValue.push('followlinks' in contextFields && contextFields.followlinks === true ? 'follow' : 'nofollow');
    if ('isPremium' in contextFields && contextFields.isPremium) {
      robotsValue.push('noarchive');
    }
    if ('metaDescription' in contextFields && typeof contextFields.metaDescription === 'string') {
      descriptionValue = contextFields.metaDescription;
    } else if (isAudioVisualArticleContext(cookData?.context)) {
      descriptionValue = 'caption' in contextFields && typeof contextFields.caption === 'string' ? contextFields.caption : '';
    }
  }

  return (
    <Head>
      {!!descriptionValue && <meta name="description" content={descriptionValue} />}
      {robotsValue?.length > 0 && <meta name="robots" content={robotsValue.join(',')} />}
    </Head>
  );
}
