import { AudioVisualArticle, TextualArticle, isAudioVisualArticleContext, isTextualArticleContext } from '@hubcms/domain-cook';
import { getElement, getElementField } from '@hubcms/utils-story-elements';

export function getIntro(context: AudioVisualArticle['context'] | TextualArticle['context']): string | undefined {
  if (isTextualArticleContext(context)) {
    const intro = getElement(context.elements, 'intro');
    const introValue = getElementField<string>(intro?.fields, 'intro');
    const teaserIntro = getElement(context.elements, 'teaser_intro');
    const teaserIntroValue = getElementField<string>(teaserIntro?.fields, 'teaserIntro');
    return introValue || teaserIntroValue || undefined;
  }
  if (isAudioVisualArticleContext(context)) {
    return context.fields.intro || undefined;
  }
  return undefined;
}
