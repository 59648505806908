import { ReactElement } from 'react';
import Script from 'next/script';
import Head from 'next/head';

import { b2bUrlBrandCode, b2bDMPMetaTagBrandCode } from '@hubcms/brand';
import { useEnv } from '@hubcms/data-access-env';
import { generateCacheBuster } from '@hubcms/utils-caching';

type TPianoLoaderProps = {
  articleId: string | null;
  isPremium: boolean | null;
  tagNames: string[];
  taxonomy: string[];
  zipCode: string | null;
};

export const PianoLoader = ({
  articleId = null,
  isPremium = null,
  tagNames = [],
  taxonomy = [],
  zipCode = null,
}: Partial<TPianoLoaderProps>): ReactElement | null => {
  const { MH_ENV } = useEnv() ?? {};
  const isProd = MH_ENV === 'PROD';

  if (!b2bDMPMetaTagBrandCode) {
    return null;
  }

  const cacheBuster = generateCacheBuster(new Date());
  const cxsenseSrc = isProd
    ? `https://shared.mediahuis.be/cxense/cxense.${b2bUrlBrandCode}.min.js?v=${cacheBuster}`
    : `https://previewshared.mediahuis.be/cxense/cxense.${b2bUrlBrandCode}.min.js?v=${cacheBuster}`;

  const metaNamePrefix = `cXenseParse:${b2bDMPMetaTagBrandCode}`;

  return (
    <>
      <Head>
        <meta name={`${metaNamePrefix}-domain`} content={b2bUrlBrandCode} />
        {articleId && <meta name={`${metaNamePrefix}-articleid`} content={articleId} />}
        {isPremium !== null && <meta name={`${metaNamePrefix}-article_ispaidcontent`} content={String(isPremium)} />}
        {taxonomy.map(taxonomySlug => (
          <meta key={`taxonomy-${taxonomySlug}`} name="cXenseParse:Taxonomy" content={`${b2bUrlBrandCode}${taxonomySlug}`} />
        ))}
        {tagNames.map((tagName, tagIndex) => (
          <meta
            key={`tag-${tagName}`}
            name={`${metaNamePrefix}-${tagIndex === 0 ? 'article_maintag' : 'article_tag'}`}
            content={tagName}
          />
        ))}
        {zipCode && <meta name={`${metaNamePrefix}-page_zipcode`} content={zipCode} />}
      </Head>
      <Script strategy="afterInteractive" src={cxsenseSrc} />
    </>
  );
};
