import { SectionParams } from '@hubcms/domain-cook';
import { TeaserLabelsVisibility } from '@hubcms/domain-teaser';

export function getTeaserLabelsVisibility(sectionParams: SectionParams): TeaserLabelsVisibility {
  const hideEditorialTeaserLabel = sectionParams['teaserlabel.hide'] === 'true';
  const hideEditorialTeaserSublabel = sectionParams['teasersublabel.hide'] === 'true';
  const hideSponsoredTeaserLabel = sectionParams['teaserlabel.b2b.hide'] === 'true';
  const hideSponsoredTeaserSublabel = sectionParams['teasersublabel.b2b.hide'] === 'true';

  return {
    showEditorialTeaserLabel: !hideEditorialTeaserLabel,
    showEditorialTeaserSublabel: !hideEditorialTeaserSublabel,
    showSponsoredTeaserLabel: !hideSponsoredTeaserLabel,
    showSponsoredTeaserSublabel: !hideSponsoredTeaserSublabel,
  };
}
