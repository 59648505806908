import { AudioVisualArticle, TextualArticle, isTextualArticleContext } from '@hubcms/domain-cook';
import { getElement, getElementField } from '@hubcms/utils-story-elements';

export function getHeadline(context: AudioVisualArticle['context'] | TextualArticle['context']): string {
  if (isTextualArticleContext(context)) {
    const headline = getElement(context.elements, 'headline');
    const headlineValue = getElementField<string>(headline?.fields, 'headline');
    const teaserHeadline = getElement(context.elements, 'teaser_headline');
    const teaserHeadlineValue = getElementField<string>(teaserHeadline?.fields, 'teaserHeadline');
    return headlineValue || teaserHeadlineValue || context.title;
  }
  return context.title;
}
