import { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { SectionParams } from '@hubcms/domain-cook';
import { getTeaserLabelsVisibility } from './getTeaserLabelsVisibility';

type GetTeaserPropsOptions = Pick<TeaserPropsOptions, 'todaysDate'> & {
  sectionParams?: SectionParams;
};

export function getTeaserPropsOptions({ sectionParams = {}, ...teaserPropsOptions }: GetTeaserPropsOptions): TeaserPropsOptions {
  return {
    ...teaserPropsOptions,
    ...getTeaserLabelsVisibility(sectionParams),
  };
}
